import { defineNuxtRouteMiddleware, navigateTo } from "#app";
import { ROUTES_NAME } from "~/config/router";

export default defineNuxtRouteMiddleware(() => {
  const tokenCookie = useCustomCookie("token");
  if (!tokenCookie.value) {
    return;
  }
  return navigateTo({
    path: ROUTES_NAME.LOBBY,
    replace: true,
    force: true,
  });
});
